









































import Vue from "vue";
import {Component, Emit, Prop, PropSync, Watch} from "vue-property-decorator";
import CreateTournamentFormNextButton
  from "@/components/Tournaments/CreateTournament/CreateTournamentFormNextButton.vue";
import {ValidationProvider} from "vee-validate/dist/vee-validate.full";
import {ICreateTournamentConstraints, ICreateTournamentRequest} from "@/services/account/tournament.types";
import {Getter} from "vuex-class";
import {CreateTournamentModuleGetterTypes} from "@/store/modules/tournaments/createTournament/types";
import {StoreNamespace} from "@/store/types";
import {VuexState} from "@/core/vuex/types";
import {ITournamentForm} from "@/core/tournaments/tournament-form.entity";
import {IConstraintEntity} from "@/core/tournaments/constraints/constraint.entity";
import CreateTournamentFormBackButton
  from "@/components/Tournaments/CreateTournament/CreateTournamentFormBackButton.vue";
import {ITournamentTargetParamEntity} from "@/core/tournaments/tournament-target-param.entity";
import { map } from 'lodash'
import {ITournamentTargetModeEntity} from "@/core/tournaments/tournament-target-mode.entity";
import CreateTournamentFormSubmitButton
  from "@/components/Tournaments/CreateTournament/CreateTournamentFormSubmitButton.vue";
import {ValidationObserver} from "vee-validate";
import {IConstraintValueEntity} from "@/core/tournaments/constraints/constraint-value.entity";

export interface ICreateTournamentFormFourthStepForm {
  constraints: ICreateTournamentConstraints[]
  target_mode_id: number | null
  target_param_id: number | null
}

interface IAvailableConstraintSelectData {
  id: number
  name: string
  multiple: boolean
  values: Form.ISelectNumberValueOption[]
}

@Component({
  components: {
    CreateTournamentFormSubmitButton,
    CreateTournamentFormBackButton, ValidationProvider, CreateTournamentFormNextButton}
})
export default class CreateTournamentFormFourthStep extends Vue {
  @PropSync('currentActiveStepNumber',{ type: Number, required: true }) private currentActiveStepNumberSync!: number
  @Prop({ type: Boolean, required: true }) private invalid!: boolean
  @Prop({ type: Boolean, required: false, default: false }) private readonly isFormProcessing!: boolean
  @Prop({ type: Object, required: true }) private parentForm!: ICreateTournamentRequest
  @Prop({ required: true }) private formObserver!: InstanceType<typeof ValidationObserver>

  @Getter(CreateTournamentModuleGetterTypes.CURRENT_VUEX_STATE, { namespace: StoreNamespace.CreateTournament })
  private readonly currentState!: VuexState

  @Getter(CreateTournamentModuleGetterTypes.TOURNAMENT_FORM, { namespace: StoreNamespace.CreateTournament })
  private readonly tournamentForm!: ITournamentForm

  private currentStepNumber: number = 4
  private isEditable: boolean = false

  private form: ICreateTournamentFormFourthStepForm = {
    constraints: this.parentForm.constraints,
    target_mode_id: this.parentForm.target_mode_id,
    target_param_id: this.parentForm.target_param_id,
  }

  get isStepCompleted () {
    return this.currentActiveStepNumberSync > this.currentStepNumber
  }

  get targetModes (): Form.ISelectNumberValueOption[] {
    if (this.currentState === VuexState.INITIALIZED) {
      return map(this.tournamentForm.targets.modes, (targetMode: ITournamentTargetModeEntity ) => {
            return {
              text: `${targetMode.tag} / ${targetMode.target_mode_type}`,
              value: targetMode.id,
              disabled: false
            } as Form.ISelectNumberValueOption
          })
    }

    return []
  }

  get targetParams (): Form.ISelectNumberValueOption[] {
    if (this.currentState === VuexState.INITIALIZED) {
      return map(this.tournamentForm.targets.params, (param: ITournamentTargetParamEntity ) => {
        return {
          text: param.tag,
          value: param.id,
          disabled: false
        } as Form.ISelectNumberValueOption
      })
    }

    return []
  }

  get availableConstraints (): IAvailableConstraintSelectData[] {
    if (this.currentState === VuexState.INITIALIZED) {
      return map(this.tournamentForm.constraints, (constraint: IConstraintEntity) => {
        return {
          id: constraint.id,
          name: constraint.type,
          multiple: constraint.is_multi_choice,
          values: map(constraint.values, (constraintValue: IConstraintValueEntity) => {
            return {
              text: constraintValue.tag,
              value: constraintValue.id,
              disabled: false
            } as Form.ISelectNumberValueOption
          })
        } as IAvailableConstraintSelectData
      })
    }

    return []
  }

  @Emit()
  private onDataChange () {
    return this.form
  }

  @Watch('currentActiveStepNumberSync')
  onCurrentActiveStepNumberSyncChange (newValue: number) {
    if (newValue === this.currentStepNumber && !this.isEditable) {
      this.isEditable = true
    }
  }

  @Watch('availableConstraints')
  onAvailableConstraintsChange (newValue: IAvailableConstraintSelectData[]) {
    this.form.constraints = map(newValue, (constraintEntity: IAvailableConstraintSelectData) => {
      return {
        id: constraintEntity.id,
        values: []
      }
    })
  }
}
