






import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component({})
export default class CreateTournamentFormSubmitButton extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) private readonly loading!: boolean
  @Prop({ type: Boolean, required: false, default: false }) private readonly disabled!: boolean
}
