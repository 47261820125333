













import {Component, Vue} from "vue-property-decorator";
import CreateTournamentForm from "@/components/Tournaments/CreateTournament/CreateTournamentForm.vue";
import ResponsiveContainer from "@/components/Shared/ResponsiveContainer.vue";

@Component({
  components: {ResponsiveContainer, CreateTournamentForm}
})
export default class CreateTournamentPage extends Vue {

}
