var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-stepper-step',{attrs:{"complete":_vm.isStepCompleted,"step":_vm.currentStepNumber,"editable":_vm.isEditable}},[_vm._v(" Configure details "),(_vm.isError)?_c('small',{staticClass:"error--text"},[_vm._v("Error! Check Fields")]):_vm._e()]),_c('v-stepper-content',{staticClass:"mb-3",attrs:{"step":_vm.currentStepNumber}},[_c('v-container',{staticClass:"pa-0 pt-1",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{staticClass:"pr-md-3",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Tournament type","items":_vm.tournamentTypes,"error-messages":errors,"outlined":"","dense":"","required":""},on:{"change":_vm.onDataChange},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})]}}])})],1),_c('v-col',{staticClass:"pl-md-3",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"is_open","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Tournament privacy","items":_vm.privacyTypes,"error-messages":errors,"outlined":"","dense":"","required":""},on:{"change":_vm.onDataChange},model:{value:(_vm.form.is_open),callback:function ($$v) {_vm.$set(_vm.form, "is_open", $$v)},expression:"form.is_open"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pr-md-3",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"is_individual","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Teams","items":_vm.teamTypes,"error-messages":errors,"outlined":"","dense":"","required":""},on:{"change":_vm.onDataChange},model:{value:(_vm.form.is_individual),callback:function ($$v) {_vm.$set(_vm.form, "is_individual", $$v)},expression:"form.is_individual"}})]}}])})],1),_c('v-col',{staticClass:"pl-md-3",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"tournament_max_participants_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Max participants","items":_vm.maxParticipantsTypes,"error-messages":errors,"outlined":"","dense":"","required":""},on:{"change":_vm.onDataChange},model:{value:(_vm.form.tournament_max_participants_id),callback:function ($$v) {_vm.$set(_vm.form, "tournament_max_participants_id", $$v)},expression:"form.tournament_max_participants_id"}})]}}])})],1)],1)],1),_c('CreateTournamentFormNextButton',{staticClass:"mr-3",attrs:{"current-active-step-number":_vm.currentActiveStepNumberSync,"current-step-number":_vm.currentStepNumber},on:{"update:currentActiveStepNumber":function($event){_vm.currentActiveStepNumberSync=$event},"update:current-active-step-number":function($event){_vm.currentActiveStepNumberSync=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }