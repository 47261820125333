
















































import Vue from "vue";
import {Component, Emit, Prop, PropSync, Watch} from "vue-property-decorator";
import CreateTournamentFormNextButton
  from "@/components/Tournaments/CreateTournament/CreateTournamentFormNextButton.vue";
import CreateTournamentFormBackButton
  from "@/components/Tournaments/CreateTournament/CreateTournamentFormBackButton.vue";
import {TournamentType} from "@/core/tournaments/tournament.entity";
import {Getter} from "vuex-class";
import {CreateTournamentModuleGetterTypes} from "@/store/modules/tournaments/createTournament/types";
import {StoreNamespace} from "@/store/types";
import {VuexState} from "@/core/vuex/types";
import {
  ITournamentForm,
  ITournamentFormMaxParticipants
} from "@/core/tournaments/tournament-form.entity";
import {ValidationProvider} from "vee-validate/dist/vee-validate.full";
import {find, has} from "lodash";
import {ICreateTournamentRequest} from "@/services/account/tournament.types";
import {ValidationObserver} from "vee-validate";
import {map} from 'lodash'

export interface ICreateTournamentFormThirdStepForm {
  type: TournamentType
  is_open: boolean
  is_individual: boolean
  tournament_max_participants_id: number | null
}

@Component({
  components: {
    ValidationProvider,
    CreateTournamentFormBackButton,
    CreateTournamentFormNextButton
  }
})
export default class CreateTournamentFormThirdStep extends Vue {
  @PropSync('currentActiveStepNumber',{ type: Number, required: true }) private currentActiveStepNumberSync!: number
  @Prop({ type: Boolean, required: true }) private invalid!: boolean
  @Prop({ type: Object, required: true }) private parentForm!: ICreateTournamentRequest
  @Prop({ required: true }) private formObserver!: InstanceType<typeof ValidationObserver>

  @Getter(CreateTournamentModuleGetterTypes.CURRENT_VUEX_STATE, { namespace: StoreNamespace.CreateTournament })
  private readonly currentState!: VuexState

  @Getter(CreateTournamentModuleGetterTypes.TOURNAMENT_FORM, { namespace: StoreNamespace.CreateTournament })
  private readonly tournamentForm!: ITournamentForm

  private currentStepNumber: number = 3
  private isEditable: boolean = false

  private form: ICreateTournamentFormThirdStepForm = {
    is_individual: this.parentForm.is_individual,
    is_open: this.parentForm.is_open,
    tournament_max_participants_id: this.parentForm.tournament_max_participants_id,
    type: this.parentForm.type,
  }

  get isStepCompleted () {
    return this.currentActiveStepNumberSync > this.currentStepNumber
  }

  get isError () {
    if (this.formObserver) {
      return (has(this.formObserver.errors, 'type') && this.formObserver.errors['type'].length > 0) ||
          (has(this.formObserver.errors, 'is_open') && this.formObserver.errors['is_open'].length > 0) ||
          (has(this.formObserver.errors, 'is_individual') && this.formObserver.errors['is_individual'].length > 0) ||
          (has(this.formObserver.errors, 'tournament_max_participants_id') && this.formObserver.errors['tournament_max_participants_id'].length > 0);
    }
    return false
  }

  get tournamentTypes (): Form.ISelectStringValueOption[] {
    return [
      { text: 'Normal', value: TournamentType.NORMAL, disabled: true },
      { text: 'Rank', value: TournamentType.RANKED, disabled: false }
    ]
  }

  get privacyTypes (): Form.ISelectBooleanValueOption[] {
    return [
      { text: 'Open', value: true, disabled: false },
      { text: 'Private', value: false, disabled: false }
    ]
  }

  get teamTypes (): Form.ISelectBooleanValueOption[] {
    return [
      { text: 'Individual', value: true, disabled: false },
      { text: 'Team', value: false, disabled: true }
    ]
  }

  get maxParticipantsTypes (): Form.ISelectNumberValueOption[] {
    if (this.currentState === VuexState.INITIALIZED) {
      return map(this.tournamentForm.max_participants, (max_participants: ITournamentFormMaxParticipants) => {
        return {
          text: max_participants.value.toString(),
          value: max_participants.id,
          disabled: false
        } as Form.ISelectNumberValueOption
      })
    }

    return [] as Form.ISelectNumberValueOption[]
  }

  @Emit()
  private onDataChange () {
    return this.form
  }

  @Watch('maxParticipantsTypes')
  private onMaxParticipantsTypesChange (newMaxParticipantsList: Form.ISelectNumberValueOption[], oldsMaxParticipantsList: Form.ISelectNumberValueOption[]) {
    if (newMaxParticipantsList.length !== oldsMaxParticipantsList.length) {
      // set default
      const defaultMaxParticipants = find(newMaxParticipantsList, (newMaxParticipants: Form.ISelectNumberValueOption) => newMaxParticipants.text === '10') as Form.ISelectNumberValueOption
      if (defaultMaxParticipants) {
        this.form.tournament_max_participants_id = defaultMaxParticipants.value
        this.onDataChange()
      }
    }
  }

  @Watch('currentActiveStepNumberSync')
  onCurrentActiveStepNumberSyncChange (newValue: number) {
    if (newValue === this.currentStepNumber && !this.isEditable) {
      this.isEditable = true
    }
  }
}
