






































import Vue from "vue";
import {Component, Emit, Prop, PropSync, Watch} from "vue-property-decorator";
import CreateTournamentFormNextButton
  from "@/components/Tournaments/CreateTournament/CreateTournamentFormNextButton.vue";
import {ValidationProvider} from "vee-validate/dist/vee-validate.full";
import CreateTournamentFormBackButton
  from "@/components/Tournaments/CreateTournament/CreateTournamentFormBackButton.vue";
import {Getter} from "vuex-class";
import {CreateTournamentModuleGetterTypes} from "@/store/modules/tournaments/createTournament/types";
import {StoreNamespace} from "@/store/types";
import {ITournamentForm, ITournamentFormDuration} from "@/core/tournaments/tournament-form.entity";
import DateTimePicker from "@/components/Shared/DateTimePicker.vue";
import {VuexState} from "@/core/vuex/types";
import {find, has} from 'lodash'
import {ICreateTournamentRequest} from "@/services/account/tournament.types";
import {ValidationObserver} from "vee-validate";
import {map} from 'lodash'

export interface ICreateTournamentFormSecondStepForm {
  start_time: number
  tournament_duration_id: number | null
}

@Component({
  components: {
    DateTimePicker,
    CreateTournamentFormBackButton,
    ValidationProvider,
    CreateTournamentFormNextButton
  }
})
export default class CreateTournamentFormSecondStep extends Vue {
  @PropSync('currentActiveStepNumber',{ type: Number, required: true }) private currentActiveStepNumberSync!: number
  @Prop({ type: Boolean, required: true }) private invalid!: boolean
  @Prop({ type: Object, required: true }) private parentForm!: ICreateTournamentRequest
  @Prop({ required: true }) private formObserver!: InstanceType<typeof ValidationObserver>

  @Getter(CreateTournamentModuleGetterTypes.CURRENT_VUEX_STATE, { namespace: StoreNamespace.CreateTournament })
  private readonly currentState!: VuexState

  @Getter(CreateTournamentModuleGetterTypes.TOURNAMENT_FORM, { namespace: StoreNamespace.CreateTournament })
  private readonly tournamentForm!: ITournamentForm

  private currentStepNumber: number = 2
  private isEditable: boolean = false

  private form: ICreateTournamentFormSecondStepForm = {
    start_time: this.parentForm.start_time,
    tournament_duration_id: this.parentForm.tournament_duration_id,
  }

  get isStepCompleted () {
    return this.currentActiveStepNumberSync > this.currentStepNumber
  }

  get isError () {
    if (this.formObserver) {
      return (has(this.formObserver.errors, 'start_time') && this.formObserver.errors['start_time'].length > 0) ||
          (has(this.formObserver.errors, 'tournament_duration_id') && this.formObserver.errors['tournament_duration_id'].length > 0);
    }
    return false
  }

  get durations (): Form.ISelectNumberValueOption[] {
    if (this.currentState === VuexState.INITIALIZED) {
      return map(this.tournamentForm.durations, (duration: ITournamentFormDuration) => {
        return {
          text: duration.name,
          value: duration.id,
          disabled: false
        } as Form.ISelectNumberValueOption
      })
    }

    return [] as Form.ISelectNumberValueOption[]
  }

  @Emit()
  private onDataChange () {
    return this.form
  }

  @Watch('durations')
  private onDurationsChange (newDurationList: Form.ISelectNumberValueOption[], oldDurationList: Form.ISelectNumberValueOption[]) {
    if (newDurationList.length !== oldDurationList.length) {
      // set default
      const defaultDuration = find(newDurationList, (newDuration: Form.ISelectNumberValueOption) => newDuration.text === '10 mins') as Form.ISelectNumberValueOption

      if (defaultDuration) {
        this.form.tournament_duration_id = defaultDuration.value
        this.onDataChange()
      }
    }
  }

  @Watch('currentActiveStepNumberSync')
  onCurrentActiveStepNumberSyncChange (newValue: number) {
    if (newValue === this.currentStepNumber && !this.isEditable) {
      this.isEditable = true
    }
  }
}
