






import Vue from "vue";
import {Component, Prop, PropSync} from "vue-property-decorator";

@Component({})
export default class CreateTournamentFormBackButton extends Vue {
  @PropSync('currentActiveStepNumber',{ type: Number, required: true }) private currentActiveStepNumberSync!: number
  @Prop({ type: Number, required: true, default: 0 }) private readonly currentStepNumber!: number

  private onBackStepAction () {
    this.currentActiveStepNumberSync = this.currentStepNumber - 1
  }
}
