


































import Vue from "vue";
import {Component} from "vue-property-decorator";
import CreateTournamentFormFirstStep
  , {ICreateTournamentFormFirstStepForm} from "@/components/Tournaments/CreateTournament/CreateTournamentSteps/CreateTournamentFormFirstStep.vue";
import CreateTournamentFormSecondStep
  , {ICreateTournamentFormSecondStepForm} from "@/components/Tournaments/CreateTournament/CreateTournamentSteps/CreateTournamentFormSecondStep.vue";
import CreateTournamentFormThirdStep
  , {ICreateTournamentFormThirdStepForm} from "@/components/Tournaments/CreateTournament/CreateTournamentSteps/CreateTournamentFormThirdStep.vue";
import CreateTournamentFormFourthStep
  , {ICreateTournamentFormFourthStepForm} from "@/components/Tournaments/CreateTournament/CreateTournamentSteps/CreateTournamentFormFourthStep.vue";
import {ValidationObserver} from "vee-validate/dist/vee-validate.full";
import {accountService} from "@/services";
import {NotificationService} from "@/components/Notifications/notification.service";
import {ICreateTournamentRequest} from "@/services/account/tournament.types";
import {GameType} from "@/core/games/types";
import {TournamentType} from "@/core/tournaments/tournament.entity";
import {CreateTournamentStoreModule} from "@/store";
import {RouteNames} from "@/router/types";

@Component({
  components: {
    ValidationObserver,
    CreateTournamentFormFourthStep,
    CreateTournamentFormThirdStep, CreateTournamentFormSecondStep, CreateTournamentFormFirstStep}
})
export default class CreateTournamentForm extends Vue {
  private currentActiveStepNumber: number = 1

  private formObserver: InstanceType<typeof ValidationObserver> | null = null
  private form: ICreateTournamentRequest = {
    constraints: [], // fourth
    game_id: GameType.LeagueOfLegends,
    is_individual: true, // third
    is_open: true, // third
    name: 'Example Tournament Title', // first
    start_time: 0, // second
    target_mode_id: null, // fourth
    target_param_id: null, // fourth
    tournament_duration_id: null, // second
    tournament_max_participants_id: null, // third
    type: TournamentType.RANKED // third
  }
  private isFormProcessing: boolean = false

  private created () {
    if (this.form.game_id !== GameType.None) {
      CreateTournamentStoreModule.setTournamentForm(this.form.game_id)
    }
  }

  private mounted () {
    if (this.$refs.createTournamentValidationObserver) {
      this.formObserver = this.$refs.createTournamentValidationObserver as InstanceType<typeof ValidationObserver>
    }
  }

  private async onSubmitAction () {
    this.isFormProcessing = true
    const createTournamentResult = await accountService.createTournament(this.form)
    if (createTournamentResult.isFailure()) {
      this.isFormProcessing = false
      if (createTournamentResult.error.errors && this.formObserver) {
        this.formObserver.setErrors(createTournamentResult.error.errors)
      }
      NotificationService.showErrorNotification(createTournamentResult.error.message)
    } else {
      NotificationService.showSuccessNotification('Tournament created')
      await this.$router.push({ name: RouteNames.TournamentRoute, params: { tournamentId: createTournamentResult.value.id.toString() } })
      this.isFormProcessing = false
    }
  }

  private onDataChange (form: ICreateTournamentFormFirstStepForm | ICreateTournamentFormSecondStepForm | ICreateTournamentFormThirdStepForm | ICreateTournamentFormFourthStepForm) {
    this.form = {...this.form, ...form}
  }

  private beforeDestroy () {
    CreateTournamentStoreModule.clearStore()
  }
}
