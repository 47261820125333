






import Vue from "vue";
import {Component, Prop, PropSync} from "vue-property-decorator";

@Component({})
export default class CreateTournamentFormNextButton extends Vue {
  @PropSync('currentActiveStepNumber',{ type: Number, required: true }) private currentActiveStepNumberSync!: number
  @Prop({ type: Number, required: true, default: 0 }) private readonly currentStepNumber!: number
  @Prop({ type: Boolean, required: false, default: false }) private readonly loading!: boolean
  @Prop({ type: Boolean, required: false, default: false }) private readonly disabled!: boolean

  private onNextStepAction () {
    this.currentActiveStepNumberSync = this.currentStepNumber + 1
  }
}
