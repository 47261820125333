












































import Vue from "vue";
import {Component, ModelSync, Prop, Watch} from "vue-property-decorator";
import {Moment} from "moment";
import {Timing} from "@/core/helpers/Timing";
import {ValidationProvider} from "vee-validate";

enum DateTimePickerType {
  Date,
  Time
}

@Component({
  components: {ValidationProvider}
})
export default class DateTimePicker extends Vue {
  @ModelSync('dateTime', 'change') dateTimeModel!: number
  @Prop({ type: String, required: true, default: 'Date Time Picker' }) private label!: string
  @Prop({ type: String, required: true }) private validationProviderName!: string
  @Prop({ type: Number, required: false, default: 0 }) private addToMinutesOnInit!: number
  private isMenuOpen: boolean = false
  private activeTab: DateTimePickerType = DateTimePickerType.Date
  private momentDateTime: Moment | null = null
  private dateFormat: string = 'YYYY-MM-DD'
  private timeFormat: string = 'HH:mm'
  private dateTimeFormat: string = `${this.dateFormat} ${this.timeFormat}`
  private date: string = ''
  private time: string = ''

  get formattedDatetime (): string {
    if (this.momentDateTime === null) return ''
    return Timing.formatMoment(this.momentDateTime, this.dateTimeFormat)
  }

  get formattedDate (): string {
    if (this.momentDateTime === null) return ''
    return Timing.formatMoment(this.momentDateTime, this.dateFormat)
  }

  get formattedTime (): string {
    if (this.momentDateTime === null) return ''
    return Timing.formatMoment(this.momentDateTime, this.timeFormat)
  }

  private mounted () {
    this.init()
  }

  private init () {
    if (!this.dateTimeModel) {
      if (this.addToMinutesOnInit === 0) {
        this.calculateDateTimeFromMoment(Timing.getTimeNow())
      } else {
        this.calculateDateTimeFromMoment(Timing.getTimeNow().add(this.addToMinutesOnInit, 'minutes'))
      }
      return
    }

    this.calculateDateTimeFromMoment(Timing.UnixToMoment(this.dateTimeModel))
  }

  private resetPicker () {
    this.isMenuOpen = false
    this.activeTab = DateTimePickerType.Date
    if (this.$refs.timePicker) {
      (this.$refs.timePicker as any).selectingHour = true
    }
  }

  private showTimePicker () {
    this.activeTab = DateTimePickerType.Time
  }

  private calculateDateTimeFromMoment (moment: Moment) {
    this.momentDateTime = moment
    this.dateTimeModel = this.momentDateTime.unix()
    this.date = this.formattedDate
    this.time = this.formattedTime
  }

  private calculateDateTime () {
    this.momentDateTime = Timing.StringToMoment(`${this.date} ${this.time}`)
    this.dateTimeModel = this.momentDateTime.unix()
    this.date = this.formattedDate
    this.time = this.formattedTime
  }

  @Watch('dateTimeModel')
  onDateTimeModelChange (newValue: number, oldValue: number) {
    if (newValue !== oldValue) {
      this.init()
    }
  }

  @Watch('date')
  onDateChange () {
    this.calculateDateTime()
  }

  @Watch('time')
  onTimeChange () {
    this.calculateDateTime()
  }

  @Watch('isMenuOpen')
  onIsMenuOpenChange (newValue: boolean, oldValue: boolean) {
    if (oldValue && !newValue) {
      this.resetPicker()
    }
  }
}
